import React from "react";
import Logo from "../assets/images/logo.png";
import "../styles/Header.css";
import { Icon } from "@iconify/react";
import "react-hamburger-menus/dist/style.css";
import Menu from "./Menu";
import { Link } from "react-router-dom";

function Header() {
  return (
    <div className="Header-component">
      <div className="header-div">
        <a
          target="_blank"
          href="http://rabetettehadco.ir/"
          className="header-btn"
        >
          <Icon
            style={{ fontSize: "20px", marginLeft: "8px" }}
            icon="twemoji:flag-iran"
          />
          Persian
        </a>
        <div className="header-menu">
          <ul>
          <li>
              <Link to="/gallery">Gallery</Link>
            </li>
            <li>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.shop.rabetettehadco.ir/contactUs"
              >
                Contact Us
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                target="_blank"
                href="http://www.shop.rabetettehadco.ir/agentForm"
              >
                Agents
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                target="_blank"
                href="http://www.shop.rabetettehadco.ir/"
              >
                Shop
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://blog.rabetettehadco.ir/"
              >
                Blog
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.shop.rabetettehadco.ir/aboutUs"
              >
                About Us
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                target="_blank"
                href="http://www.rabetettehadco.ir/"
              >
                Home
              </a>
            </li>
            <li>
              <Link to="/gallery">Gallery</Link>
            </li>
          </ul>
        </div>
        <img src={Logo} alt="Rabet Etehad Logo" />
      </div>
      <Menu />
    </div>
  );
}

export default Header;
