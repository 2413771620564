import React from "react";
import "../styles/Footer.css";
import { Icon } from "@iconify/react";
import { AiFillStar } from "react-icons/ai";
import download1 from "../assets/images/DownloadApp/1.png";
import download2 from "../assets/images/DownloadApp/2.png";
import download3 from "../assets/images/DownloadApp/3.png";
import sibche from "../assets/images/Sibche.png";
import anarduni from "../assets/images/anarduni.png";
import sibapp from "../assets/images/sibapp.png";

function Footer() {
  return (
    <div className="footer-component">
      <div className="footer">
        <div className="footer-divs">
          <h4>Contacts</h4>
          <div style={{ margin: "12px 0" }} className="footer-adress">
            <div className="phones">
              <span
                style={{
                  width: "100%",
                  textAlign: "justify",
                  textJustify: "auto",
                }}
              >
                Head Office, Tehran: Abbas Abad, Beheshti St., after Andisheh
                Square, Behesht Building, 4th floor
              </span>
              <span
                style={{
                  width: "100%",
                  textAlign: "justify",
                  textJustify: "auto",
                }}
              >
                Tabriz head office: Palestine St., next to children's clinic,
                No. 336/1
              </span>
            </div>
            <div className="phones">
              <span>04132801930 - 04132840226</span>
              <span>04132840954 - 04132840955</span>
            </div>
          </div>
        </div>
        <div className="footer-divs">
          <h4>Quick access</h4>
          <div style={{ margin: "12px 0" }} className="links">
            <a href="http://www.shop.rabetettehadco.ir/">
              <Icon icon="ci:dot-04-l" />
              <span style={{ marginRight: "12px" }}>Shop</span>
            </a>
            <a href="https://blog.rabetettehadco.ir/">
              <Icon icon="ci:dot-04-l" />
              <span style={{ marginRight: "12px" }}>Blog</span>
            </a>
            <a href="http://www.shop.rabetettehadco.ir/warranty">
              <Icon icon="ci:dot-04-l" />
              <span style={{ marginRight: "12px" }}>Warranty follow-up</span>
            </a>
            <a href="http://www.shop.rabetettehadco.ir/instagram">
              <Icon icon="ci:dot-04-l" />
              <span style={{ marginRight: "12px" }}>Lottery</span>
            </a>
            <a href="http://www.shop.rabetettehadco.ir/agentForm">
              <Icon icon="ci:dot-04-l" />
              <span style={{ marginRight: "12px" }}>Agents</span>
            </a>
          </div>
        </div>
        <div className="footer-divs">
          <h4>About Us</h4>
          <div
            style={{
              margin: "12px 0",
              display: "flex",
              alignItems: "",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                color: "#2B3467",
              }}
            >
              <span
                style={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "justify",
                  textJustify: "auto",
                }}
              >
                <AiFillStar style={{ color: "#EDBB11", paddingLeft: "8px" }} />
                Etehad is a familiar and trusted name in Iran's electrical
                industry
              </span>
              <span
                style={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "justify",
                  textJustify: "auto",
                }}
              >
                <AiFillStar style={{ color: "#EDBB11", paddingLeft: "8px" }} />
                Etehad Nagin Derashkan in Iran's electrical and electronic
                industries
              </span>
              <span
                style={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "justify",
                  textJustify: "auto",
                }}
              >
                <AiFillStar style={{ color: "#EDBB11", paddingLeft: "8px" }} />
                Quality is also a form of honesty
              </span>
            </div>
          </div>
         
        </div>
      </div>
      <div className="downloadApp">
            <a
              className="downloadAppButton"
              href="http://www.shop.rabetettehadco.ir/pwa"
            >
              <img src={download1} alt="downloadApp" />
            </a>
            <a
              className="downloadAppButton"
              href="https://play.google.com/store/apps/details?id=com.rabetettehad&gl=GB"
            >
              <img src={download2} alt="downloadApp" />
            </a>
            <a
              className="downloadAppButton"
              href="https://cafebazaar.ir/app/com.rabetettehad"
            >
              <img src={download3} alt="downloadApp" />
            </a>
        
            {/* <a className="downloadAppButton" href="#">
              <img src={sibche} alt="downloadApp" />
            </a>
            <a className="downloadAppButton" href="#">
              <img src={sibapp} alt="downloadApp" />
            </a>
            <a className="downloadAppButton" href="#">
              <img src={anarduni} alt="downloadApp" />
            </a> */}
          </div>
      <div className="footer-bottom-creator">
        <span>
          All rights are reserved and belong to
          <span style={{ color: "rgb(255, 114, 33)" }}> rabetettehad</span>|
          Designed and developed by
          <a
            style={{
              textDecoration: "none",
              color: "#ffffff",
              marginLeft: "3px",
            }}
            href="https://platinco.ir/"
          >
            Platin
          </a>
        </span>
      </div>
    </div>
  );
}

export default Footer;
