import React from "react";
import Body from "./Body";
import Header from "./Header";
import VideoSlider from "./VideoSlider";
import Footer from "./Footer";

function AllCom(props) {
  return (
    <div>
      <Header />
      <VideoSlider />
      <Body />
      <Footer />
    </div>
  );
}

export default AllCom;
