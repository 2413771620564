import React from "react";
import ph2 from "../assets/images/gallery/2.jpg";
import ph3 from "../assets/images/gallery/3.jpg";
import ph4 from "../assets/images/gallery/4.jpg";
import ph5 from "../assets/images/gallery/5.jpg";
import ph6 from "../assets/images/gallery/6.jpg";
import ph7 from "../assets/images/gallery/7.jpg";
import ph8 from "../assets/images/gallery/8.jpg";
import ph9 from "../assets/images/gallery/9.jpg";
import ph10 from "../assets/images/gallery/10.jpg";
import ph11 from "../assets/images/gallery/11.jpg";
import ph12 from "../assets/images/gallery/12.jpg";
import ph13 from "../assets/images/gallery/13.jpg";
import ph14 from "../assets/images/gallery/14.jpg";
import ph15 from "../assets/images/gallery/15.jpg";
import ph16 from "../assets/images/gallery/16.jpg";
import ph17 from "../assets/images/gallery/17.jpg";
import ph18 from "../assets/images/gallery/18.jpg";
import ph19 from "../assets/images/gallery/19.jpg";
import ph20 from "../assets/images/gallery/20.jpg";
import ph21 from "../assets/images/gallery/21.jpg";
import ph22 from "../assets/images/gallery/22.jpg";
import ph23 from "../assets/images/gallery/23.jpg";
import ph24 from "../assets/images/gallery/24.jpg";
import ph25 from "../assets/images/gallery/25.jpg";
////
import ph_1 from "../assets/images/gallery2/1.jpg";
import ph_2 from "../assets/images/gallery2/2.jpg";
import ph_3 from "../assets/images/gallery2/3.jpg";
import ph_4 from "../assets/images/gallery2/4.jpg";
import ph_5 from "../assets/images/gallery2/5.jpg";
import ph_6 from "../assets/images/gallery2/6.jpg";
import ph_7 from "../assets/images/gallery2/7.jpg";
import ph_8 from "../assets/images/gallery2/8.jpg";
import ph_9 from "../assets/images/gallery2/9.jpg";
import ph_10 from "../assets/images/gallery2/10.jpg";
import ph_11 from "../assets/images/gallery2/17.jpg";
import ph_12 from "../assets/images/gallery2/12.jpg";
import ph_13 from "../assets/images/gallery2/13.jpg";
import ph_15 from "../assets/images/gallery2/15.jpg";
import ph_16 from "../assets/images/gallery2/16.jpg";
import "../styles/Gallery.css";
import Header from "./Header";
import Footer from "./Footer";
import { Rings } from "react-loader-spinner";
function GalleryImage(props) {
  return (
    <>
      <Header />
      <div className="wrapper">
        <div className="headerSpinner">
          <span>
            <Rings
              height="50"
              width="50"
              color="#9b1c53"
              radius="6"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="rings-loading"
            />
          </span>
          <h1>
            {" "}
            Pictures of <span>Elecomp</span> exhibition{" "}
          </h1>
        </div>
      </div>
      <div className="container">
        <div className="c1">
          <img className="img-res " src={ph2} alt="" />
        </div>
        <div className="c3">
          <img className="img-res" src={ph3} alt="" />
        </div>
        <div className="c2">
          <img className="img-res" src={ph4} alt="" />
        </div>
        <div className="c4">
          <img className="img-res" src={ph5} alt="" />
        </div>
        <div className="c5">
          <img className="img-res" src={ph6} alt="" />
        </div>
        <div className="c6">
          <img className="img-res " src={ph7} alt="" />
        </div>
        <div className="c7">
          <img className="img-res" src={ph8} alt="" />
        </div>
        <div className="c8">
          <img className="img-res" src={ph9} alt="" />
        </div>
        <div className="c9">
          <img className="img-res" src={ph10} alt="" />
        </div>
        <div className="c10">
          <img className="img-res" src={ph11} alt="" />
        </div>
        <div className="c11">
          <img className="img-res" src={ph12} alt="" />
        </div>
        <div className="c12">
          <img className="img-res" src={ph13} alt="" />
        </div>
        <div className="c13">
          <img className="img-res" src={ph14} alt="" />
        </div>
        <div className="c14">
          <img className="img-res" src={ph15} alt="" />
        </div>
        <div className="c15">
          <img className="img-res" src={ph16} alt="" />
        </div>
        <div className="c16">
          <img className="img-res" src={ph17} alt="" />
        </div>
        <div className="c17">
          <img className="img-res" src={ph18} alt="" />
        </div>
        <div className="c18">
          <img className="img-res" src={ph19} alt="" />
        </div>
        <div className="c19">
          <img className="img-res" src={ph20} alt="" />
        </div>
        <div className="c20">
          <img className="img-res" src={ph21} alt="" />
        </div>
        <div className="c21">
          <img className="img-res" src={ph22} alt="" />
        </div>
        <div className="c22">
          <img className="img-res" src={ph23} alt="" />
        </div>
        <div className="c23">
          <img className="img-res" src={ph24} alt="" />
        </div>
        <div className="c24">
          <img className="img-res" src={ph25} alt="" />
        </div>
      </div>
      <div className="wrapper">
        <div className="headerSpinner2">
          <span>
            <Rings
              height="50"
              width="50"
              color="#ea882b"
              radius="6"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="rings-loading"
            />
          </span>
          <h1>
            {" "}
            Pictures of the <span>Rabet Ettehad</span> factory
          </h1>
        </div>
      </div>
      <div className="container">
        <div className="c1">
          <img className="img-res " src={ph_1} alt="" />
        </div>
        <div className="c3">
          <img className="img-res" src={ph_2} alt="" />
        </div>
        <div className="c2">
          <img className="img-res" src={ph_3} alt="" />
        </div>
        <div className="c4">
          <img className="img-res" src={ph_4} alt="" />
        </div>
        <div className="c5">
          <img className="img-res" src={ph_5} alt="" />
        </div>
        <div className="c6">
          <img className="img-res " src={ph_6} alt="" />
        </div>
        <div className="c7">
          <img className="img-res" src={ph_7} alt="" />
        </div>
        <div className="c8">
          <img className="img-res" src={ph_8} alt="" />
        </div>
        <div className="c9">
          <img className="img-res" src={ph_9} alt="" />
        </div>
        <div className="c10">
          <img className="img-res" src={ph_10} alt="" />
        </div>
        <div className="c11">
          <img className="img-res" src={ph_11} alt="" />
        </div>
        <div className="c12">
          <img className="img-res" src={ph_12} alt="" />
        </div>
        <div className="c13">
          <img className="img-res" src={ph_13} alt="" />
        </div>
        <div className="c14">
          <img className="img-res" src={ph_15} alt="" />
        </div>

        <div className="c15">
          <img className="img-res" src={ph_16} alt="" />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default GalleryImage;
