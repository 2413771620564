import React from "react";
import "../styles/VideoSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import Fade from "react-reveal/Fade";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import video from "../assets/videos/vidSlide.mp4";
import SliderImage from "../assets/images/slider2.jpg";

function VideoSlider() {
  return (
    <>
      <div className="Slider-Component">
        <Swiper
          autoplay={{ delay: 2500 }}
          pagination={true}
          navigation={true}
          modules={[Pagination, Navigation, Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide style={{ position: "relative" }}>
            <video
              style={{
                position: "absolute",
                zIndex: "1",
                filter: "brightness(30%)",
                borderRadius: "0",
                height: "100%",
                objectFit: "cover",
              }}
              muted
              loop
              autoPlay={true}
              src={video}
            />
            <Fade bottom>
              <div className="slider-text">
                <h1>Rabet Etehad company</h1>
                <h2 style={{ color: "white" }}>
                  Quality is also a form of honesty
                </h2>
              </div>
            </Fade>
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={SliderImage}
              alt="Slider2"
              style={{
                width: "100vw",
                filter: "brightness(30%)",
                position: "absolute",
                zIndex: "1",
              }}
            />
            <div className="slider-text">
              <h1>Rabet Etehad company</h1>
              <h2 style={{ color: "white" }}>
                Quality is also a form of honesty
              </h2>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}

export default VideoSlider;
