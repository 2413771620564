import React, { useState } from "react";
import "../styles/Body.css";
import Logo from "../assets/images/logo.png";
import Catalog from "../assets/images/Cables/catalog.png";
import { AiFillStar } from "react-icons/ai";
import AboutVideo from "../assets/videos/vid1.mp4";
import Fade from "react-reveal/Fade";
import test from "../assets/images/test.jpg";
import lighting from "../assets/images/Cables/Lighting.jpg";
import microImage from "../assets/images/Cables/black.jpg";
import { HiLocationMarker } from "react-icons/hi";
import { AiFillPhone } from "react-icons/ai";
import partlogo1 from "../assets/images/Brands/1.png";
import partlogorabet from "../assets/images/Brands/2.png";
import partlogo3 from "../assets/images/Brands/3.png";
import partlogo4 from "../assets/images/Brands/5.png";
import logo4 from "../assets/images/Brands/4.png";

function Body() {
  const [lightining, setLightining] = useState(false);
  const [micro, setMicro] = useState(true);
  const [typec, setTypec] = useState(false);

  const lightiningFn = () => {
    if (lightining === false) {
      setLightining(true);
      setTypec(false);
      setMicro(false);
    }
  };
  const typecFn = () => {
    if (typec === false) {
      setTypec(true);
      setMicro(false);
      setLightining(false);
    }
  };
  const microFn = () => {
    if (micro === false) {
      setMicro(true);
      setTypec(false);
      setLightining(false);
    }
  };

  return (
    <div className="Body">
      <div className="about-rabet">
        <div className="about-right">
          <Fade right>
            <h2
              className="about-top-title"
              style={{
                borderBottom: "2px solid",
                display: "inline-block",
                paddingBottom: "8px",
                color: "#2B3467",
              }}
            >
              Rabet Etehad company
            </h2>
            <Fade right>
              <div style={{ textAlign: "justify", textJustify: "auto" }}>
                This company was established and started operating as a new unit
                of Ittihad Industrial Production Group in 2019 with the aim of
                producing electrical and electronic connectors. Due to the
                country's abundant and general consumption of connectors and
                cables for mobile-printer-tablet chargers. Computer - audio and
                video equipment and homogeneous products and knowledge of
                domestic production of these products ، With the help of
                Almighty God and relying on the strength of its technical
                craftsmen and the support of 30 years of continuous production
                and successful presence in the consumer market as the first
                domestic manufacturer, Behsaz Co. Micro-printer-computer and
                laptop have started. By obtaining the necessary permits for
                establishment and operation from the Ministry of Industries and
                Mines of the country, Behsaz Co., Ltd. started its production in
                accordance with the quality system of the industrial production
                complex of Etihad and in the way of using global standards, the
                approval of the quality of the production complex, the quality
                of the manufactured products and Cera. has received
              </div>
            </Fade>
            <div className="about-bottom">
              <div
                className="about-bottom-child"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "24px 0",
                  color: "#2B3467",
                }}
              >
                <span className="about-spans">
                  <AiFillStar
                    style={{ color: "#EDBB11", paddingLeft: "8px" }}
                  />
                  <p>
                    Etehad is a familiar and trusted name in Iran's electrical
                    industry
                  </p>
                </span>
                <span className="about-spans">
                  <AiFillStar
                    style={{ color: "#EDBB11", paddingLeft: "8px" }}
                  />
                  <p>
                    Etehad Nagin Derashkan in Iran's electrical and electronic
                    industries
                  </p>
                </span>
                <span className="about-spans">
                  <AiFillStar
                    style={{ color: "#EDBB11", paddingLeft: "8px" }}
                  />
                  <p>Quality is also a form of honesty</p>
                </span>
              </div>
              <img className="about-logo" src={Logo} alt="Rabet Logo" />
            </div>
          </Fade>
        </div>
        <Fade left>
          <video className="about-vid" controls src={AboutVideo}></video>
        </Fade>
      </div>
      <div className="RabetCatalog">
        <div className="catalogtext">
          <div className="text">
            <h2>Download Catalog</h2>
            <span className="textdis">
              From this section, you can download the catalog of the company
              Behsaz, which includes the introduction of the company's products,
              the introduction of communication routes, etc.
            </span>
            <Fade bottom>
              <div className="btncnter">
                <a
                  href="https://rabetettehadco.ir/Download/catalog.pdf"
                  className="catalogdownload"
                  target="_blank"
                  rel="noreferrer"
                >
                  Download Catalog
                </a>
              </div>
            </Fade>
          </div>
        </div>
        <div className="catalogimg">
          <Fade left>
            <img className="catalogimgsize" src={Catalog} alt="" />
          </Fade>
        </div>
      </div>
      <div className="products">
        <div className="product_details">
          {lightining === true ? (
            <Fade right>
              <h2
                className="product-title"
                style={{
                  borderBottom: "2px solid black",
                  padding: "12px 0",
                  fontSize: "26px",
                }}
              >
                Lightning charging cable
              </h2>
              <div
                style={{
                  textAlign: "justify",
                  textJustify: "auto",
                  fontSize: "16px",
                }}
              >
                Lightning charger cable is specially designed and made by Apple.
                Lightning chargers replace the original 30-pin dock connectors
                on the iPhone 3 or iPhone 4.
              </div>
            </Fade>
          ) : micro === true ? (
            <Fade right>
              <h2
                className="product-title"
                style={{
                  borderBottom: "2px solid black",
                  padding: "12px 0",
                  fontSize: "26px",
                }}
              >
                Micro USB charging cable
              </h2>
              <div
                style={{
                  textAlign: "justify",
                  textJustify: "auto",
                  fontSize: "16px",
                }}
              >
                Micro USB chargers are one of the most common types of mobile
                chargers sold in the world. In fact, brands like Samsung and LG
                have almost made these chargers the standard model of mobile
                chargers in the world
              </div>
            </Fade>
          ) : typec === true ? (
            <Fade right>
              <h2
                className="product-title"
                style={{
                  borderBottom: "2px solid black",
                  padding: "12px 0",
                  fontSize: "26px",
                }}
              >
                USB type C charging cable
              </h2>
              <div
                style={{
                  textAlign: "justify",
                  textJustify: "auto",
                  fontSize: "16px",
                }}
              >
                USB Type C chargers entered the world of charger ports
                relatively recently. They only last a few years and are the best
                alternative to common charging cables with ports like A or B.
              </div>
            </Fade>
          ) : null}
          <Fade right>
            <div className="products-buttons-div">
              <button
                className={lightining ? "product_btn_enable" : "product_btn"}
                onClick={lightiningFn}
              >
                Lightning charging cable
              </button>
              <button
                className={typec ? "product_btn_enable" : "product_btn"}
                onClick={typecFn}
              >
                USB type C charging cable
              </button>
              <button
                className={micro ? "product_btn_enable" : "product_btn"}
                onClick={microFn}
              >
                Micro USB charging cable
              </button>
            </div>
          </Fade>
        </div>
        <div className="product_image">
          {lightining === true ? (
            <Fade left>
              <img className="cable-img" src={lighting} alt="Lightining" />
            </Fade>
          ) : micro === true ? (
            <Fade left>
              <img className="cable-img" src={microImage} alt="Lightining" />
            </Fade>
          ) : typec === true ? (
            <Fade left>
              <img className="cable-img" src={test} alt="Lightining" />
            </Fade>
          ) : null}
        </div>
      </div>
      <div className="contact-us">
        <div className="contact-box">
          <div className="contact-about">
            <span className="about-title">Contact US</span>
            <div className="adress">
              <HiLocationMarker className="Icons" />
              <div className="phones">
                <span>
                  Head Office, Tehran: Abbas Abad, Beheshti St., after Andisheh
                  Square, Behesht Building, 4th floor
                </span>
                <span>
                  Tabriz head office: Palestine St., next to children's clinic,
                  No. 336/1
                </span>
              </div>
            </div>
            <div className="adress">
              <AiFillPhone className="Icons" />
              <div className="phones">
                <span>04132801930 - 04132840226</span>
                <span>04132840954 - 04132840955</span>
              </div>
            </div>
          </div>
          <div className="contact-form">
            <form id="form">
              <div className="inputs1">
                <input
                  className="form-name"
                  placeholder="name"
                  type="text"
                  required
                />
                <input
                  className="form-email"
                  placeholder="email"
                  type="email"
                  required
                />
              </div>
              <div className="inputs">
                <input
                  className="form-subject"
                  placeholder="subject"
                  type="text"
                  required
                />
                <input
                  className="form-msg"
                  placeholder="message ..."
                  type="text"
                  required
                />
              </div>
              <div className="submit-div">
                <button
                  type="submit"
                  // onClick={sendMessage}
                  className="submit-form-btn"
                >
                  send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="partner-div">
        <h2 style={{ color: "#ffffff", fontWeight: "500", fontSize: "36px" }}>
          Sub Brands
        </h2>
        <div className="logos-partners">
          <Fade right>
            <a href="http://etlink.ir/">
              <img className="brand-logo" src={partlogo1} alt="" />
            </a>
            <a href="http://rabetettehadco.ir/">
              <img className="brand-logo" src={partlogorabet} alt="" />
            </a>
          </Fade>
          <Fade bottom>
            <a href="http://masterlinkplus.com/">
              <img className="brand-logo" src={partlogo3} alt="" />
            </a>
          </Fade>
          <Fade left>
            <a href="https://www.shop.en.rabetettehadco.ir/">
              <img className="brand-logo logo4" src={logo4} alt="" />
            </a>
            <a href="http://we-netplus.com/">
              <img className="brand-logo" src={partlogo4} alt="" />
            </a>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default Body;
